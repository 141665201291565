.Flat {
    position: relative;
    height: 100%;
    background: #FAFAFA;
    overflow-y: auto;
}
.FlatData {
    height: 100%;
    position: relative;
}
.FlatInfo {
    position: absolute;
    right: 50px;
    top: 0;
    width: 352px;
    font-family: Circe, 'CirceRounded';
}
.FlatInfo-block {
    padding: 31px 0 0;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    width: 100%;
    margin-top: 89px;
    position: relative;
}
.FlatInfo-block .FlatsResultTable-rowFinishingTrue {
    position: absolute;
    top: 0;
    right: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
}
.FlatName-title {
    font-weight: 500;
    font-size: 24px;
    color: #242424;
    letter-spacing: 0;
    line-height: 32px;
    padding: 0 32px 12px;
    border-bottom: 1px solid #f0f1f2;
}
.FlatInfo-rowFinishing {
    padding: 4px 8px 3px;
    border-radius: 3px;
    border: 1px solid #e6e6e6;
    width: 78px;
    margin: 14px 0 16px 31px;
    font-size: 10px;
    color: #ccc;
    letter-spacing: 0;
    text-align: center;
    font-weight: 500;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.FlatInfo-rowFinishingTrue {
    color: #55bb01;
    padding: 4px 6px 2px 5px;
    border: 1px solid rgba(85,187,1,.3);
    width: 85px;
}
.FlatInfo-rowFinishingTrue svg {
    fill: #41d95a;
    width: 12px;
    height: 9px;
    margin-right: 4px;
}
svg:not(:root) {
    overflow: hidden;
}
.FlatInfo-table {
    display: flex;
flex-direction: column;    margin-bottom: 16px;
}
.FlatInfo-row {
    display: flex;
    border-bottom: 1px solid #f0f1f2;
    padding: 12px 32px;
    box-sizing: border-box;
}
.FlatInfo-col{
    flex: 1;
}
.FlatInfo-row:last-child {
    border: none;
    padding-bottom: 0;
}
.FlatInfo-nom, .FlatInfo-value {
    font-size: 16px;
    color: #222;
    letter-spacing: 0;
    display: block;
}
.FlatInfo-nom {
    font-weight: 300;
    font-size: 14px;
}
.FlatInfo-value--red {
    font-weight: 500;
    color: #f63;
}
.FlatImages {
    position: relative;
    width: calc(100% - 401px);
    height: 100%;
}
.PlaningSlide {
    width: 100%;
    height: 100vh;
    position: relative;
    opacity: 1;
    transition: opacity .2s ease;
}
.FlatImages .PlaningSlide {
    height: 100%;
}
.PlaningSlide-swipe {
    user-select: none;
    z-index: 8;
}
.PlaningSlide-image, .PlaningSlide-swipe {
    position: absolute;
    width: 100%;
    height: 100%;
}
.PlaningSlide-image {
    top: 0;
    left: 0;
    overflow: hidden;
    will-change: width;
    transform: translateZ(0);
    backface-visibility: hidden;
}
.PlaningSlide-image--planing {
    background-color: #e6ebed;
    transition-timing-function: linear;
    transition-duration: .1s;
    transition-property: width;
    will-change: width;
}
.PlaningSlide-image--planing.no-bg {
    background-color: transparent;
}
.PlaningSlide-background {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: 0;
    width: calc(100vw - 420px);
    text-align: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
}
.FlatImages .PlaningSlide-background {
    width: 100%;
    max-height: calc(100% - 250px);
    margin-top: 180px;
}
.close {
    position: absolute;
    cursor: pointer;
    right: 50px;
    top: 30px;
    z-index: 1000;
}
.FlatData-floor {
    width: calc(100% - 461px);
    max-height: calc(100% - 250px);
    margin: 200px 30px 0;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    text-align: center;
}
.FlatData-floor a {
    width: 100%;
    height: 100%;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
}
.Flat .LockIcon {
    fill: #ffbd13;
    width: 10px;
    height: 13px;
    margin-left: 15px;
    position: absolute;
    top: 31px;
    right: 150px;
    z-index: 1000000;
}
.Flat .LockIcon:hover:before {
    border-width: 6px 0 6px 8px;
    border-color: transparent transparent transparent #2e2e2e;
    right: calc(100% + 6px);
    left: auto;
}
.Flat .LockIcon:hover:after {
    right: calc(100% + 10px);
    left: auto;
}
.r-select .r-select__head{
    position: relative;
}
.r-select .r-select__head::after{
    content: ' ';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: #000 transparent transparent transparent;
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    pointer-events: none;
}
.r-select.active .r-select__head::after{
    transform: rotate(180deg);
}
