.FlatFilter-button {
    margin-top: 16px;
}
.FlatFilter-buttonSubmit {
    background-color: #17A322;
    color: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    height: 48px;
    width: 100%;
    cursor: pointer;
    font-family: Circe, 'CirceRounded';
    font-weight: 500;
    transition: background-color .2s ease-in-out;
}
.FlatFilter-buttonSubmit:hover {
    background-color: #2a8031;
}
.FlatFilter-showAll {
    letter-spacing: 0;
    text-align: center;
    line-height: 16px;
    margin-top: 18px;
    cursor: pointer;
    font-size: 13px;
    color: #959ba6;
}