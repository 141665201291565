.FlatFilter-inputsWrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
}
.FlatFilter-inputsWrapperPrice .FlatFilter-inputsItem {
    pointer-events: none;
}
.FlatFilter-inputs .rc-slider-mark {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    font-size: 12px;
}
.FlatFilter-inputs .rc-slider-handle {
    outline: none;
    position: absolute;
    margin-top: -8px;
    margin-left: -8px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    cursor: -webkit-grab;
    cursor: grab;
    border-radius: 50%;
    touch-action: pan-x;
    background: #fff;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,.05), 0 2px 10px 0 rgba(0,0,0,.1);
}
.FlatFilter-inputs .rc-slider {
    position: relative;
    height: 14px;
    margin: 3px 0;
    padding: 5px 0;
    width: 100%;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
}
.FlatFilter-inputs .rc-slider-handle-1 {
    margin-left: 0;
}
.FlatFilter-inputs .rc-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
}
.FlatFilter-inputs .rc-slider-track {
    position: absolute;
    left: 0;
    height: 2px;
    border-radius: 6px;
    background-color: #106EB1;
}
.FlatFilter-inputs .rc-slider-rail {
    position: absolute;
    width: 100%;
    background-color: #e6e6e6;
    height: 2px;
    border-radius: 6px;
}
.FlatFilter-inputsWrapperPrice .FlatFilter-inputsItem:last-child input {
    text-align: right;
}