button:focus, input:focus, textarea:focus {
    outline: none;
}
.FlatFilter-wrapper {
    padding: 0 0 3px;
    border: 1px solid #efefef;
    border-radius: 4px;
    background-color: #fff;
}
.FlatFilter-wrapper:first-child {
    padding: 24px 0 0;
}
.Picking-filterScroll {
    transition: top .3s linear;
}
.FlatFilter {
    width: 354px;
    position: relative;
}
.FlatFilter-wrapper {
    padding: 0 0 3px;
    border: 1px solid #efefef;
    border-radius: 4px;
    background-color: #fff;
}
.FlatFilter-wrapper:first-child {
    padding: 24px 0 0;
}
.FlatFilter-filter {
    margin-bottom: 26px;
    padding: 0 24px;
}
.FlatCheckbox {
    display: flex;
    align-items: center;
}
.FlatCheckboxChecked {
    display: none;
}
.FlatCheckboxCheckedCustom {
    position: relative;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border: 2px solid #d9d9d9;
    border-radius: 2px;
    display: block;
    transition: all .2s ease;
}
.FlatCheckboxChecked:checked+.FlatCheckboxCheckedCustom {
    border-color: #106EB1;
}
.FlatCheckboxChecked:checked+.FlatCheckboxCheckedCustom:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background:#106EB1;
}

.FlatCheckboxCheckedCustom svg {
    transition: all .2s ease;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 1px;
    left: 0;
}
svg:not(:root) {
    overflow: hidden;
}
.FlatCheckboxChecked:checked+.FlatCheckboxCheckedCustom svg {
    visibility: visible;
    opacity: 1;
}
.FlatFilter-check:last-child .FlatFilter-checkCheckedCustom {
    border-right: 1px solid #e6e6e6;
    border-radius: 0 4px 4px 0;
}
.FlatFilter-checkChecked:checked+.FlatFilter-checkCheckedCustom {
    background-color: #106eb1;
    border-color: #0f5d94;
}
.FlatFilter-checkChecked:checked+.FlatFilter-checkCheckedCustom span {
    color: #fff;
}
.FlatCheckboxTitle {
    margin-left: 8px;
    font-size: 14px;
    color: #222;
    font-family: Circe, 'CirceRounded';
}
button, input, optgroup, select, textarea {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}
button, input {
    overflow: visible;
}
[type=checkbox], [type=radio] {
    box-sizing: border-box;
    padding: 0;
}
.FlatFilter-name {
    font-weight: 400;
    font-size: 11px;
    color: #959ba6;
    margin-bottom: 9px;
}
.FlatFilter-buttonsWrapper {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    background: #fff;
    border-radius: 4px;
}
.FlatFilter-buttonsWrapperChecked {
    display: flex;flex-wrap: wrap;
}
.FlatFilter-check {
    height: 44px;
    cursor: pointer;
    flex: 0 0 25%;
}
.FlatFilter-check:last-of-type{
    flex: 1;
}
.FlatFilter-checkChecked {
    display: none;
}
.FlatFilter-checkCheckedCustom {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    display: flex;
    transition: all .2s ease;
    border: 1px solid #e6e6e6;
}
.FlatFilter-check:first-child .FlatFilter-checkCheckedCustom {
    border-radius: 4px 0 0 4px;
}
.FlatFilter-inputsItem {
    height: 42px;
    flex: 0 0 124px;
}
.FlatFilter-inputsWrapperPrice .FlatFilter-inputsItem {
    height: 24px;
}
.Input {
    height: 100%;
    width: 100%;
    position: relative;
}
.Input input {
    display: block;
    padding: 18px 0 0 12px;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-family: Circe, 'CirceRounded';
    font-size: 14px;
    color: #484848;
    letter-spacing: 0;
}
.Input input {
    font-size: 18px;
    color: #222;
    display: flex;
    justify-content: flex-start;
    padding: 0 12px;
}
.Input-withPercents, .Input input {
    flex-flow: row nowrap;
    align-items: center;
}
.FlatFilter-inputsWrapperPrice .Input input {
    padding: 0;
    border: none;
    font-size: 15px;
    color: #222;
}
.Picking-popup {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    z-index: -1;
    transition: top .4s ease;
    animation-duration: .2s;
    animation-timing-function: ease-in-out;
    animation-name: popupHidden;
}
.Picking-popup--show {
    top: 0;
    visibility: visible!important;
    z-index: 1000;
    background-color: rgba(0,0,0,.46);
    animation-name: popupShow;
}
.Picking-popupFilter {
    background-color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 103px 0 22px;
    animation-duration: .2s;
    animation-timing-function: ease-in-out;
    animation-name: popupBodyHidden;
}
@keyframes popupShow {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}
@keyframes popupBodyShow {
    0% {
        transform: scale(.75)
    }

    to {
        transform: scale(1)
    }
}
.Picking-popupHead {
    display: none;
    justify-content: space-between;
    height: 48px;
    box-sizing: border-box;
    padding: 0 12px;
    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;
}
.Picking-popupHead, .Picking-popupTitle {
    flex-flow: row nowrap;
    align-items: center;
}
.Picking-filter-wrapper .btnScheme {
    width: 100%;
    display: block;
    box-sizing: border-box;
    text-align: center;
    background: #fff;
    margin-bottom: 10px;    
    color: #505050!important;
}